<template>
  <div class="container-top">
    <div class="pc">
      <div class="section-1">
        <div class="container">
          <div class="col-6 position-relative" style="height:100%">
            <!--<div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>-->
            <img class="selection1-img" :src="img('img_main_banner2x')" alt="">
          </div>
          <div class="col-6 flex-center position-relative">
            <div>
              <div class="h8 primary">플랫폼 퀵-빌더 오픈베타 1.0 런칭</div>
              <div class="main weight-500"
                   style="margin:32px 0 16px 0;line-height:63px;font-size:44px">플랫폼 창업을 위한<br>가장 혁신적인 솔루션
              </div>
              <div class="body1 sub2">런치팩의 플랫폼 퀵-빌더 솔루션으로<br>
                개발자, 디자이너 없이 바로 웹서비스 런칭 가능!</div>
              <div class="flex" style="margin-top:80px">
                <button class="button-home button-primary" style="margin-right:10px" @click="routerPush('/quickbuilder')">
                  <div class="flex" style="justify-content: space-between">
                    <div class="subtitle4">퀵-빌더로<br>바로 런칭하기</div>
                    <img :src="icon('u_arrow-up-right')" class="svg-white" style="width:24px;height:24px;">
                  </div>
                </button>
                <button class="button-home button-primary-light" style="margin-left:10px" @click="routerPush('/info?id=1')">
                  <div class="flex" style="justify-content: space-between">
                    <div class="subtitle4">커스터마이징<br>하기</div>
                    <img :src="icon('u_arrow-up-right')" class="svg-primary" style="width:24px;height:24px;">
                  </div>
                </button>
              </div>
            </div>
            <img class="pattern-dot" src="/static/img/home/dot.svg">
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="container">
          <div class="col-6">
            <div class="h8 primary">Why</div>
            <div class="subtitle1 main margin-top-16">플랫폼 사업 시작을 망설이고<br>계셨나요?</div>
            <div class="sec2-box" style="margin-top:56px">
              <img src="/static/img/home/u_money-stack.svg" style="margin-right:24px">
              <div class="body1 sub">개발 예산이 부족해서</div>
            </div>
            <div class="sec2-box" style="margin-top:20px">
              <img src="/static/img/home/u_clock-three_color.svg" style="margin-right:24px">
              <div class="body1 sub">개발 완료까지 오래 기다리고 싶지 않아서</div>
            </div>
            <div class="sec2-box" style="margin-top:20px">
              <img src="/static/img/home/u_file-question.svg" style="margin-right:24px">
              <div class="body1 sub">결과물의 퀄리티가 보장되지 못해서</div>
            </div>
          </div>
          <div class="col-6">
            <img :src="img('section_right_img')" alt="플랫폼 고민">
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="container">
          <div class="col-4">
            <div class="h8 primary">Simple</div>
            <div class="subtitle1 main margin-top-16">플랫폼 사업 시작을 위해<br>필요한 건 아이디어 하나!</div>
            <div class="body1 sub3" style="margin:24px 0 100px 0">플랫폼 개발에 드는<br>시간과 비용 투자 최소화</div>
            <button class="button-home-circle" @click="routerPush('/info?id=2')">
              <div class="flex" style="justify-content: space-around">
                <div class="body3-bold">1시간 내<br>런칭</div>
                <img :src="icon('u_arrow-up-right')" class="svg-white" style="width:24px;height:24px;">
              </div>
            </button>
          </div>
          <div class="col-8 flex-end">
            <video width="100%" :autoplay="true" :loop="true" muted controls="true">
              <source src="/static/img/home/launchpack_guide.mp4" type="video/mp4">
            </video>
          </div>
          <div class="col-3" v-for="(item,idx) in steps" :key="'step-'+idx" style="padding:90px 32px 0 0">
            <div class="flex-align">
              <div class="step-icon">
                <img :src="icon(item.icon)" class="svg-primary">
              </div>
              <div class="dot-line" v-if="idx<3">
                <div v-for="i in 12" :key="'dot-'+i" class="dot"></div>
              </div>
            </div>
            <div class="body4-bold primary" style="margin-top:36px">STEP{{ idx+1 }}</div>
            <div class="h7 sub" style="margin:8px 0">{{ item.title }}</div>
            <div class="body3 sub3" style="white-space: pre-line">{{ item.content }}</div>
          </div>
        </div>
      </div>

      <div class="section-5">
        <div class="container" style="align-items: flex-start">
          <div class="col-12">
            <div style="text-align: center">
              <div class="subtitle1 main">런치팩과 함께라면 무엇이든 가능합니다</div>
              <div class="body1 sub3" style="margin-top:8px">내 서비스에 적합한 주문 방식을 선택할 수 있습니다.</div>
            </div>
          </div>
          <div class="col-12" style="margin-top:80px">
            <div class="flex-align">
              <img :src="img('img_call')" alt="전화 주문" style="width:736px;height:300px">
              <div style="margin-left:56px">
                <div class="h8 primary">01</div>
                <div class="subtitle3 main" style="margin:12px 0">줄 서서 먹는 동네 맛집에<br>미리 전화해 예약하는 서비스</div>
                <div class="body1 sub">전화 주문</div>
              </div>
            </div>

            <div class="flex-between" style="margin: 80px 0">
              <div>
                <div class="h8 primary">02</div>
                <div class="subtitle3 main" style="margin:12px 0">힙한 스트릿 브랜드들을<br>한 곳에 모아 쇼핑하는 서비스</div>
                <div class="body1 sub">상품 배송 주문</div>
              </div>
              <img :src="img('img_product')" alt="상품 배송 주문" style="width:736px;height:300px">
            </div>

            <div class="flex-align">
              <img :src="img('img_time')" alt="시간 예약 주문" style="width:736px;height:300px">
              <div style="margin-left:56px">
                <div class="h8 primary">03</div>
                <div class="subtitle3 main" style="margin:12px 0">분위기 좋은 카페를 과제용<br>미팅룸으로 예약하는 서비스</div>
                <div class="body1 sub">시간 예약 주문</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-6">
        <div class="container" style="align-items: flex-start">
          <div class="col-12 text-center">
            <div class="subtitle1 main">런치팩은 모두 준비되어 있습니다</div>
            <div class="body1 sub3" style="margin-top:8px">어떤 기능도 구현할 수 있도록</div>
          </div>
          <div class="col-12 flex-center" style="margin-top:80px">
            <div class="grid" style="grid-template-columns: repeat(4, 1fr);grid-gap: 24px;width:760px">
              <div v-for="(item,idx) in readyList" :key="'ready-'+idx" class="ready-item">
                <div>
                  <img :src="`/static/img/home/${item.icon}.svg`" :alt="item.title">
                  <div class="subtitle4 sub2" style="white-space:pre-line;margin-top:16px">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-7">
        <div class="container" style="flex-direction: column">
          <div style="text-align: center">
            <div class="subtitle1 main">지금 바로 시작해보세요</div>
            <div class="body1 sub3" style="margin-top: 8px">개발자, 디자이너가 없어 망설이던 플랫폼 서비스 런칭</div>
          </div>
          <div class="grid" style="grid-template-columns: repeat(2, 1fr);grid-gap:40px;margin-top:56px">
            <div v-for="(item,idx) in services" :key="'service-'+idx" class="card-service">
              <div>
                <div class="h4 main">{{ item.title }}</div>
                <div class="subtitle3 sub2" style="margin-top:8px">{{ item.subTitle }}</div>
                <div class="card-divider"></div>
                <div class="body3 sub2" style="height:48px;word-break: keep-all">{{ item.desc }}</div>
                <img :src="img(item.img)" :alt="item.title" style="width:100%;margin:16px 0">
                <div v-for="(checkItem, chkIdx) in item.checks" :key="'check-item-'+chkIdx">
                  <div style="margin-top:24px" class="flex">
                    <img :src="icon('u_check')" class="svg-primary" style="width:20px;height:24px">
                    <div style="margin-left:12px">
                      <div class="h8 primary">{{ checkItem.title }}</div>
                      <div class="body3 sub3" style="margin-top:8px">{{ checkItem.desc }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div v-if="item.price">
                  <div v-if="item.price.org_price" class="size-16 sub4 card-service-price">
                    <span class="price"
                          style="text-decoration: line-through">{{ item.price.org_price|currencyNum }}</span>원
                  </div>
                  <div class="flex-between">
                    <div>
                      <span v-if="item.price.sale" class="h4 primary"
                            style="margin-right:4px">{{ item.price.sale }}%</span>
                      <span class="h4 main price">{{ item.price.price|currencyNum }}</span>
                      <span class="body1 main">원</span>
                      <span class="body1 sub3">/월</span>
                      <span class="h5 main" style="margin-left:4px">~</span>
                    </div>
                    <div class="body2 sub3">소요시간 : {{ item.time }}</div>
                  </div>
                </div>
                <button class="button is-primary body2-bold" style="width:100%;margin-top:36px"
                        @click="routerPush(item.link)">신청하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-8">
        <div class="container" style="flex-direction: column">
          <div style="text-align: center">
            <div class="subtitle1 main">부가 서비스</div>
            <div class="body1 sub3" style="margin-top: 8px">더 편리하게 런치팩 솔루션을 이용하는 법</div>
          </div>
          <div class="grid" style="margin-top:80px;grid-template-columns: repeat(3, 1fr);grid-gap:24px">
            <div class="card-extra" :class="{'unselect': item.subTitle!=='Maintenance'}"
                 v-for="(item,idx) in extras" :key="'card-extra-'+idx" @click="clickExtra(item)">
              <div class="flex-between">
                <div>
                  <div class="body5-medium primary">{{ item.subTitle }}</div>
                  <div class="h5 main" style="margin-top:4px">
                    {{ item.title }}<img v-if="item.link" :src="icon('u_arrow-up-right')" style="margin-left:12px;width:24px;height:24px;">
                  </div>
                </div>
                <div class="card-extra-icon-bg">
                  <img :src="icon(item.icon)" class="svg-primary">
                </div>
              </div>
              <div class="body3 sub2" style="margin-top:20px;word-break: keep-all">{{ item.desc }}</div>
              <div v-if="item.star" style="margin-top:8px" class="body7 sub3">{{ item.star }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-4">
        <div class="container">
          <div style="width:100%">
            <div class="text-center">
              <div class="subtitle1">이런 상품들도 있습니다!</div>
              <div class="body1 sub3" style="margin-top:8px">정해진 블럭 외의 범위에서 나에게 맞게 디자인, 기능을 수정하고 싶다면</div>
            </div>

            <div class="flex-between" style="margin-top:56px">
              <div class="card-product unselect design-market" @click="routerPush('/info?id=1')">
                <div class="card-content">
                  <div class="body1">디자인 스킨을<br>수정해<br>나만의 서비스 만들기</div>
                  <div class="h4" style="margin-top:24px">디자인 마켓
                    <img :src="icon('u_arrow-up-right')" class="svg-white"
                         style="width:36px;height:36px;vertical-align: text-top">
                  </div>
                </div>
                <img :src="img('mockup_design_market')">
              </div>

              <div class="card-product unselect order" @click="routerPush('/info?id=0')">
                <div class="card-content">
                  <div class="body1">A부터 Z까지<br>나에게 맞춰서<br>제작하기</div>
                  <div class="h4" style="margin-top:24px">주문제작
                    <img :src="icon('u_arrow-up-right')" class="svg-white"
                         style="width:36px;height:36px;vertical-align: text-top">
                  </div>
                </div>
                <img :src="img('img_order_make')">
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="section-9">
        <div class="container">
          <div class="col-5">
            <div class="subtitle1 main">런치팩 공식 제휴사</div>
          </div>
          <div class="col-7">
            <div class="flex-between">
              <img :src="img('logo_kakaopay')" alt="카카오페이" style="width:110px;height:110px">
              <img :src="img('img_aws')" alt="AWS">
              <img :src="img('img_kg')" alt="KG 이니시스">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tablet">
      <div class="section-1">
        <div class="container">
          <div class="col-6 position-relative" style="height:100%">
            <!--<div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>-->
            <img class="selection1-img" :src="img('img_main_banner2x')" alt="">
          </div>
          <div class="col-6 flex-center position-relative">
            <div>
              <div class="main subtitle2"
                   style="margin:-40px 0px 16px;line-height:40px">플랫폼 창업을 위한<br>가장 혁신적인 솔루션
              </div>
              <div class="body4 sub2">런치팩의 플랫폼 퀵-빌더 솔루션으로<br>
                개발자, 디자이너 없이 바로 웹서비스 런칭 가능!</div>
              <div class="flex" style="margin-top:80px">
                <button class="button-home button-primary" style="margin-right:10px" @click="routerPush('/info?id=2')">
                  <div class="flex" style="justify-content: space-between">
                    <div class="body4-bold">퀵-빌더로<br>바로 런칭하기</div>
                    <img :src="icon('u_arrow-up-right')" class="svg-white" style="width:24px;height:24px;">
                  </div>
                </button>
                <button class="button-home button-primary-light" style="margin-left:10px" @click="routerPush('/info?id=1')">
                  <div class="flex" style="justify-content: space-between">
                    <div class="body4-bold">커스터마이징<br>하기</div>
                    <img :src="icon('u_arrow-up-right')" class="svg-primary" style="width:24px;height:24px;">
                  </div>
                </button>
              </div>
            </div>
            <img class="pattern-dot" src="/static/img/home/dot.svg">
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body4-bold primary">Why</div>
            <div class="body0-medium main" style="margin-top:8px">플랫폼 사업 시작을 망설이고 계셨나요?</div>
          </div>
          <div class="flex-center" style="width:100%">
            <div class="text-center">
              <img :src="img('section_right_img')" style="width:285px" alt="플랫폼 고민">
              <div class="sec2-box">
                <img src="/static/img/home/u_money-stack.svg" style="width:20px;height:20px;margin-right:12px">
                <div class="body3 sub">개발 예산이 부족해서</div>
              </div>
              <div class="sec2-box">
                <img src="/static/img/home/u_clock-three_color.svg" style="width:20px;height:20px;margin-right:12px">
                <div class="body3 sub">개발 완료까지 오래 기다리고 싶지 않아서</div>
              </div>
              <div class="sec2-box">
                <img src="/static/img/home/u_file-question.svg" style="width:20px;height:20px;margin-right:12px">
                <div class="body3 sub">결과물의 퀄리티가 보장되지 못해서</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body4-bold primary">Simple</div>
            <div class="body0-medium main" style="margin-top:8px">플랫폼 사업 시작을 위해서<br>필요한 건 아이디어 하나!</div>
            <div class="body3 sub3" style="margin-bottom:40px">플랫폼 개발에 드는 시간과 비용 투자 최소화</div>
          </div>
          <video width="100%" :autoplay="true" :loop="true" controls="true" muted style="margin-bottom:40px">
            <source src="/static/img/home/launchpack_guide.mp4" type="video/mp4">
          </video>
          <div class="flex-center" style="width:100%">
            <div>
              <div v-for="(item,idx) in steps" :key="'step-'+idx" style="margin-bottom:28px">
                <div class="flex">
                  <div class="step-icon">
                    <img :src="icon(item.icon)" class="svg-primary">
                  </div>
                  <div style="flex:1">
                    <div class="body6-bold primary">STEP{{ idx+1 }}</div>
                    <div class="body2-bold sub" style="margin:4px 0">{{ item.title }}</div>
                    <div class="body4 sub3">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-center" style="width:100%">
            <button class="button is-primary" style="height:48px;width:488px;margin-top:8px" @click="routerPush('/info?id=2')">1시간 뒤 런칭하기</button>
          </div>
        </div>
      </div>
      <div class="section-5">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body0-medium main">런치팩과 함께라면 무엇이든 가능합니다</div>
            <div class="body3 sub3" style="margin-top:4px">내 서비스에 적합한 주문 방식을 선택할 수 있습니다.</div>
          </div>
          <div class="flex-center" style="width:100%">
            <div class="flex-center" style="max-width:740px">
              <div class="col-8">
                <img :src="img('img_call')" alt="전화 주문">
              </div>
              <div class="col-4 flex-center text-center">
                <div style="width:236px">
                  <div class="h7 sub" style="margin-bottom:4px">전화 주문</div>
                  <div class="body4 sub2">줄 서서 먹는 동네 맛집에<br>미리 전화해 예약하는 서비스</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-center" style="width:100%">
            <div class="flex-center" style="max-width:740px">
              <div class="col-4 flex-center text-center">
                <div style="width:236px">
                  <div class="h7 sub" style="margin-bottom:4px">상품 배송 주문</div>
                  <div class="body4 sub2">힙한 스트릿 브랜드들을<br>한 곳에 모아 쇼핑하는 서비스</div>
                </div>
              </div>
              <div class="col-8">
                <img :src="img('img_product')" alt="상품 배송 주문">
              </div>
            </div>
          </div>
          <div class="flex-center" style="width:100%">
            <div class="flex-center" style="max-width:740px">
              <div class="col-8">
                <img :src="img('img_time')" alt="전화 주문">
              </div>
              <div class="col-4 flex-center text-center">
                <div style="width:236px">
                  <div class="h7 sub" style="margin-bottom:4px">시간 예약 주문</div>
                  <div class="body4 sub2">분위기 좋은 카페를 과제용<br>미팅룸으로 예약하는 서비스</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-6">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body0-medium main">런치팩은 모두 준비되어 있습니다</div>
            <div class="body3 sub3" style="margin:4px 0 28px 0">어떤 기능도 구현할 수 있도록</div>
          </div>
          <div class="flex-center" style="width:100%">
            <div class="grid" style="grid-template-columns: repeat(3, 1fr);grid-gap: 8px;width:488px">
              <div v-for="(item,idx) in readyList" :key="'ready-'+idx" class="ready-item">
                <div>
                  <img :src="`/static/img/home/${item.icon}.svg`" :alt="item.title" style="width:24px;height:24px">
                  <div class="body5 sub2" style="white-space:pre-line;margin-top:8px;word-break: keep-all">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-7">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">지금 바로 시작해보세요</div>
            <div class="body3 sub3" style="margin: 4px 0 28px 0">개발자, 디자이너가 없어 망설이던 플랫폼 서비스 런칭</div>
          </div>

          <div class="grid" style="grid-template-columns: repeat(2, 1fr);grid-gap:16px;margin-top:28px">
            <div v-for="(item,idx) in services" :key="'service-'+idx" class="card-service">
            <div>
              <div class="body0-bold main">{{ item.title }}</div>
              <div class="body3 sub2" style="margin-top:4px">{{ item.subTitle }}</div>
              <div class="card-divider"></div>
              <div class="size-13 sub2" style="line-height:20px;word-break: keep-all">{{ item.desc }}</div>
              <img :src="img(item.img)" :alt="item.title" style="width:100%;margin-top:16px">
              <div v-for="(checkItem, chkIdx) in item.checks" :key="'check-item-'+chkIdx">
                <div class="flex" style="margin-top:24px">
                  <img :src="icon('u_check')" class="svg-primary" style="width:20px;height:24px">
                  <div style="margin-left:12px">
                    <div class="body4-bold primary" style="word-break: keep-all">{{ checkItem.title }}</div>
                    <div class="size-13 sub3" style="line-height:20px;margin-top:2px">{{ checkItem.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-if="item.price" class="card-service-price">
                <div class="flex-between">
                  <div v-if="item.price.org_price" class="size-13 sub4">
                      <span class="price"
                            style="text-decoration: line-through">{{ item.price.org_price|currencyNum }}</span>원
                  </div>
                  <div class="sub3 size-12">소요시간 : {{ item.time }}</div>
                </div>
                <div>
                      <span v-if="item.price.sale" class="size-20 weight-700 primary"
                            style="margin-right:4px">{{ item.price.sale }}%</span>
                  <span class="size-20 weight-700 main price">{{ item.price.price|currencyNum }}</span>
                  <span class="size-14 main">원</span>
                  <span class="size-14 sub3">/월</span>
                  <span class="size-20 weight-700 main" style="margin-left:4px">~</span>
                </div>
              </div>
              <button class="button is-primary" style="width:100%;margin-top:20px;height:48px"
                      @click="routerPush(item.link)">상세보기
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="section-8">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">부가 서비스</div>
            <div class="body3 sub3" style="margin: 4px 0 0">더 편리하게 런치팩 솔루션을 이용하는 법</div>
          </div>
          <div class="grid" style="grid-template-columns: repeat(3, 1fr);grid-gap:16px;margin-top:28px">
            <div class="card-extra unselect" v-for="(item,idx) in extras" :key="'card-extra-'+idx" @click="clickExtra(item)">
              <div class="flex-between">
                <div>
                  <div class="body6-medium primary">{{ item.subTitle }}</div>
                  <div class="h7 main" style="margin-top:4px">
                    {{ item.title }}<img v-if="item.link" :src="icon('u_arrow-up-right')" style="margin-left:8px;width:20px;height:20px;">
                  </div>
                </div>
              </div>
              <div class="body4 sub3" style="margin-top:12px">{{ item.desc }}</div>
              <div v-if="item.star" style="margin-top:8px" class="body7 sub3">{{ item.star }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-4">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">이런 상품들도 있습니다!</div>
            <div class="body3 sub3" style="margin-top:4px">정해진 블럭 외의 범위에서 나에게 맞게 디자인,<br>기능을 수정하고 싶다면</div>
          </div>

          <div class="flex-between" style="margin-top:28px;width:100%">
            <div class="grid" style="grid-template-columns: repeat(2, 1fr);grid-gap: 16px;width:100%">
              <div class="card-product-mobile design-market" @click="routerPush('/info?id=1')">
                <div class="card-content">
                  <div class="body5-medium">디자인 스킨을<br>수정해<br>나만의 서비스 만들기</div>
                  <div class="body3-bold" style="margin-top:12px">디자인 마켓
                    <img :src="icon('u_arrow-up-right')" class="svg-white"
                         style="width:20px;height:20px;vertical-align: text-top">
                  </div>
<!--                  <img :src="img('mockup_design_market_mo')">-->
                </div>
              </div>

              <div class="card-product-mobile order" @click="routerPush('/info?id=0')">
                <div class="order-background-image">
                  <div class="card-content">
                    <div class="body5-medium">A부터 Z까지<br>나에게 맞춰서<br>제작하기</div>
                    <div class="body3-bold" style="margin-top:12px">주문제작
                      <img :src="icon('u_arrow-up-right')" class="svg-white"
                           style="width:20px;height:20px;vertical-align: text-top">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-9" style="padding-bottom:0">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">런치팩 공식 제휴사</div>
          </div>
          <div class="flex-between" style="width: 100%;margin-top:32px">
            <div class="col-4 text-center">
              <img style="width:60px;height:60px;" :src="img('logo_kakaopay')" alt="카카오페이">
            </div>
            <div class="col-4 text-center">
              <img style="width:90px;height:47.25px" :src="img('img_aws')" alt="AWS">
            </div>
            <div class="col-4 text-center">
              <img style="width:90px;height:46px" :src="img('img_kg')" alt="KG 이니시스">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile">
      <div class="section-1">
        <div class="position-absolute flex" style="width:100%;height:100%;top:0;left:0;padding:28px 0;flex-direction: column">
          <div style="text-align:center;padding:0 20px">
            <div class="subtitle2 main">플랫폼 창업을 위한</div>
            <div class="h4 main">가장 혁신적인 솔루션</div>
            <div class="body4 sub2" style="margin-top:8px">
              개발자, 디자이너 없이 바로 웹서비스 런칭 가능!
            </div>
          </div>
          <div style="text-align: center;margin-top:16px;flex:1;overflow: hidden;display: flex;align-items: center;justify-content: center;">
            <img :src="img('img_main_banner_mo2x')" alt="" style="height:100%;max-height: 500px;">
          </div>
          <div class="flex" style="padding:20px">
            <button class="button-home button-primary" style="margin-right:6px" @click="routerPush('/info?id=2')">
              <div class="flex" style="justify-content: space-between">
                <div class="body2-bold">퀵-빌더로<br>바로 런칭하기</div>
                <img :src="icon('u_arrow-up-right')" class="svg-white" style="width:24px;height:24px;">
              </div>
            </button>
            <button class="button-home button-primary-light" style="margin-left:6px" @click="routerPush('/info?id=1')">
              <div class="flex" style="justify-content: space-between">
                <div class="body2-bold">커스터마이징<br>하기</div>
                <img :src="icon('u_arrow-up-right')" class="svg-primary" style="width:24px;height:24px;">
              </div>
            </button>
          </div>
          <div class="flex body4 main justify-center" style="padding:4px 20px">
            <img class="bounce" :src="icon('u_arrow-down')" style="margin-right:8px"><div>런치팩 상품 더 알아보기</div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body4-bold primary">Why</div>
            <div class="body0-medium main" style="margin-top:8px">플랫폼 사업 시작을 망설이고 계셨나요?</div>
          </div>
          <img :src="img('section_right_img')" alt="플랫폼 고민">
          <div class="sec2-box" style="margin-top:28px">
            <img src="/static/img/home/u_money-stack.svg" style="width:20px;height:20px;margin-right:12px">
            <div class="body3 sub">개발 예산이 부족해서</div>
          </div>
          <div class="sec2-box" style="margin-top:20px">
            <img src="/static/img/home/u_clock-three_color.svg" style="width:20px;height:20px;margin-right:12px">
            <div class="body3 sub">개발 완료까지 오래 기다리고 싶지 않아서</div>
          </div>
          <div class="sec2-box" style="margin-top:20px">
            <img src="/static/img/home/u_file-question.svg" style="width:20px;height:20px;margin-right:12px">
            <div class="body3 sub">결과물의 퀄리티가 보장되지 못해서</div>
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body4-bold primary">Simple</div>
            <div class="body0-medium main" style="margin-top:8px">플랫폼 사업 시작을 위해서<br>필요한 건 아이디어 하나!</div>
            <div class="body3 sub3" style="margin-bottom:40px">플랫폼 개발에 드는 시간과 비용 투자 최소화</div>
          </div>
          <video width="100%" :autoplay="true" :loop="true" controls="true" muted style="margin-bottom:40px">
            <source src="/static/img/home/launchpack_guide.mp4" type="video/mp4">
          </video>
          <div v-for="(item,idx) in steps" :key="'step-'+idx" style="margin-bottom:28px">
            <div class="flex">
              <div class="step-icon">
                <img :src="icon(item.icon)" class="svg-primary">
              </div>
              <div style="flex:1">
                <div class="body6-bold primary">STEP{{ idx+1 }}</div>
                <div class="body2-bold sub" style="margin:4px 0">{{ item.title }}</div>
                <div class="body4 sub3" style="white-space: pre-line">{{ item.content }}</div>
              </div>
            </div>
          </div>
          <button class="button is-primary" style="height:48px;width:100%;margin-top:8px" @click="routerPush('/info?id=2')">1시간 뒤 런칭하기</button>
        </div>
      </div>
      <div class="section-5">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body0-medium main">런치팩과 함께라면<br>무엇이든 가능합니다</div>
            <div class="body3 sub3" style="margin-top:4px">내 서비스에 적합한 주문 방식을 선택할 수 있습니다.</div>
          </div>
          <div class="text-center" style="text-align:center;width:100%;margin:28px 0 16px 0">
            <img :src="img('img_call')" alt="전화 주문" style="width:100%;">
            <div style="margin-top:16px">
              <div class="h7 sub" style="margin-bottom:4px">전화 주문</div>
              <div class="body4 sub2">줄 서서 먹는 동네 맛집에<br>미리 전화해 예약하는 서비스</div>
            </div>
          </div>
          <div class="text-center" style="text-align:center;width:100%;margin-bottom:16px">
            <img :src="img('img_product')" alt="상품 배송 주문" style="width:100%;">
            <div style="margin-top:16px">
              <div class="h7 sub" style="margin-bottom:4px">상품 배송 주문</div>
              <div class="body4 sub2">힙한 스트릿 브랜드들을<br>한 곳에 모아 쇼핑하는 서비스</div>
            </div>
          </div>
          <div class="text-center" style="text-align:center;width:100%;margin-bottom:16px">
            <img :src="img('img_time')" alt="시간 예약 주문" style="width:100%;">
            <div style="margin-top:16px">
              <div class="h7 sub" style="margin-bottom:4px">시간 예약 주문</div>
              <div class="body4 sub2">분위기 좋은 카페를 과제용<br>미팅룸으로 예약하는 서비스</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-6">
        <div class="container">
          <div class="text-center" style="width:100%">
            <div class="body0-medium main">런치팩은 모두 준비되어 있습니다</div>
            <div class="body3 sub3" style="margin:4px 0 28px 0">어떤 기능도 구현할 수 있도록</div>
          </div>
          <div class="grid" style="grid-template-columns: repeat(3, 1fr);grid-gap: 8px;width:100%">
            <div v-for="(item,idx) in readyList" :key="'ready-'+idx" class="ready-item">
              <div>
                <img :src="`/static/img/home/${item.icon}.svg`" :alt="item.title" style="width:24px;height:24px">
                <div class="body5 sub2" style="white-space:pre-line;margin-top:8px;word-break: keep-all">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-7">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">지금 바로 시작해보세요</div>
            <div class="body3 sub3" style="margin: 4px 0 28px 0">개발자, 디자이너가 없어 망설이던 플랫폼 서비스 런칭</div>
          </div>

          <div v-for="(item,idx) in services" :key="'service-'+idx" class="card-service">
            <div>
              <div class="body0-bold main">{{ item.title }}</div>
              <div class="body3 sub2" style="margin-top:4px">{{ item.subTitle }}</div>
              <div class="card-divider"></div>
              <div class="size-13 sub2" style="line-height:20px;word-break: keep-all">{{ item.desc }}</div>
              <img :src="img(item.img)" :alt="item.title" style="width:100%;margin-top:16px">
              <div v-for="(checkItem, chkIdx) in item.checks" :key="'check-item-'+chkIdx">
                <div class="flex" style="margin-top:24px">
                  <img :src="icon('u_check')" class="svg-primary" style="width:20px;height:24px">
                  <div style="margin-left:12px">
                    <div class="body4-bold primary" style="word-break: keep-all">{{ checkItem.title }}</div>
                    <div class="size-13 sub3" style="line-height:20px;margin-top:2px">{{ checkItem.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-if="item.price" class="card-service-price">
                <div class="flex-between">
                  <div v-if="item.price.org_price" class="size-13 sub4">
                      <span class="price"
                            style="text-decoration: line-through">{{ item.price.org_price|currencyNum }}</span>원
                  </div>
                  <div class="sub3 size-12">소요시간 : {{ item.time }}</div>
                </div>
                <div>
                      <span v-if="item.price.sale" class="size-20 weight-700 primary"
                            style="margin-right:4px">{{ item.price.sale }}%</span>
                  <span class="size-20 weight-700 main price">{{ item.price.price|currencyNum }}</span>
                  <span class="size-14 main">원</span>
                  <span class="size-14 sub3">/월</span>
                  <span class="size-20 weight-700 main" style="margin-left:4px">~</span>
                </div>
              </div>
              <button class="button is-primary" style="width:100%;margin-top:20px;height:48px"
                      @click="routerPush(item.link)">상세보기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="section-8">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">부가 서비스</div>
            <div class="body3 sub3" style="margin: 4px 0 24px 0">더 편리하게 런치팩 솔루션을 이용하는 법</div>
          </div>
          <div class="card-extra unselect" v-for="(item,idx) in extras" :key="'card-extra-'+idx" @click="clickExtra(item)">
            <div class="flex-between">
              <div>
                <div class="body6-medium primary">{{ item.subTitle }}</div>
                <div class="h7 main" style="margin-top:4px">
                  {{ item.title }}<img v-if="item.link" :src="icon('u_arrow-up-right')" style="margin-left:8px;width:20px;height:20px;">
                </div>
              </div>
              <div class="card-extra-icon-bg">
                <img :src="icon(item.icon)" class="svg-primary">
              </div>
            </div>
            <div class="body4 sub3" style="margin-top:12px;word-break: keep-all">{{ item.desc }}</div>
            <div v-if="item.star" style="margin-top:8px" class="body7 sub3">{{ item.star }}</div>
          </div>
        </div>
      </div>
      <div class="section-4">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">이런 상품들도 있습니다!</div>
            <div class="body3 sub3" style="margin-top:4px">정해진 블럭 외의 범위에서 나에게 맞게 디자인,<br>기능을 수정하고 싶다면</div>
          </div>

          <div class="flex-between" style="margin-top:28px">
            <div class="card-product-mobile design-market" @click="routerPush('/info?id=1')">
              <div class="card-content">
                <div class="body5-medium">디자인 스킨을<br>수정해<br>나만의 서비스 만들기</div>
                <div class="body3-bold" style="margin-top:12px">디자인 마켓
                  <img :src="icon('u_arrow-up-right')" class="svg-white"
                       style="width:20px;height:20px;vertical-align: text-top">
                </div>
              </div>
              <img :src="img('mockup_design_market_mo')">
            </div>

            <div class="card-product-mobile order" @click="routerPush('/info?id=0')">
              <div class="card-content">
                <div class="body5-medium">A부터 Z까지<br>나에게 맞춰서<br>제작하기</div>
                <div class="body3-bold" style="margin-top:12px">주문제작
                  <img :src="icon('u_arrow-up-right')" class="svg-white"
                       style="width:20px;height:20px;vertical-align: text-top">
                </div>
              </div>
              <img :src="img('img_order_make_mo')">
            </div>

          </div>
        </div>
      </div>
      <div class="section-9" style="padding-bottom:0">
        <div class="container">
          <div style="text-align: center;width: 100%">
            <div class="body0-medium main">런치팩 공식 제휴사</div>
          </div>
          <div class="flex-between" style="width: 100%;margin-top:32px">
            <div class="col-4 text-center">
              <img style="width:60px;height:60px;" :src="img('logo_kakaopay')" alt="카카오페이">
            </div>
            <div class="col-4 text-center">
              <img style="width:90px;height:47.25px" :src="img('img_aws')" alt="AWS">
            </div>
            <div class="col-4 text-center">
              <img style="width:90px;height:46px" :src="img('img_kg')" alt="KG 이니시스">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    created() {
      if(this.isMobile) {
        this.readyList.push({
          title: '공지사항 팝업',
          icon: 'u_comment-alt-exclamation_color'
        });
      }
    },
    data() {
      return {
        steps: [
          {
            title: '사업 모델을 구상하고',
            content: '고객이 원하는 플랫폼의 비즈니스\n모델과 필요한 기능을 구상합니다.',
            icon: 'u_lightbulb-alt'
          },
          {
            title: '원하는 디자인과 기능을 조합해서',
            content: '구상한 비즈니스 모델에 맞는 \n블럭(디자인+기능)을 조합합니다.',
            icon: 'u_web-grid-alt'
          },
          {
            title: '결제하면',
            content: '필요한 부가서비스와 대금 지불 방법을\n선택하고 결제합니다.',
            icon: 'u_credit-card'
          },
          {
            title: '내 서비스 개설 완료!',
            content: '서비스 개설이 완료됩니다. 서비스는 다운로드 후 바로 사용이 가능합니다. ',
            icon: 'fi_check-circle_outline'
          },
        ],
        readyList: [
          {
            title: '입찰견적 신청',
            icon: 'u_file-alt'
          },
          {
            title: '쿠폰 / 적립금',
            icon: 'fi_tag'
          },
          {
            title: '본인인증',
            icon: 'u_user-check_color'
          },
          {
            title: '게시판',
            icon: 'u_clipboard-alt_color'
          },
          {
            title: '회원 등급 관리',
            icon: 'u_brightness-plus'
          },
          {
            title: '인스타 피드 연결',
            icon: 'u_instagram'
          },
          {
            title: '파트너 채팅',
            icon: 'u_comment-lines'
          },
          {
            title: '상품 DB\n엑셀 임포팅',
            icon: 'u_file-upload-alt'
          }
        ],
        services: [
          {
            title: '플랫폼 퀵-빌더',
            subTitle: '1시간 뒤 런칭',
            desc: '디자인 블럭을 조합해 내 플랫폼을 런칭할 수 있는 솔루션',
            img: 'img_quick_builder',
            checks: [
              {
                title: '기다리지 않고 바로 런칭',
                desc: '원하는 기능과 디자인의 컴포넌트를 조합하고 결제하면 1시간 내에 서비스 런칭 가능!'
              },
              {
                title: '전문가 없이도 쉽게 조립',
                desc: '서비스 디렉션에 따라 블럭을 조합하면 어느새 내 서비스 완성!'
              }
            ],
            price: {
              org_price: 1320000,
              sale: 20,
              price: 280000
            },
            time: '약 1시간',
            link: '/quickbuilder'
          },
          {
            title: '디자인 마켓',
            subTitle: '원하는 디자인 스킨을 고르고 수정해서 런칭',
            desc: '원하는 디자인 스킨을 선택한 후 그대로 런칭하거나 수정요청 후 런칭',
            img: 'design_market',
            checks: [
              {
                title: '플랫폼 운영에 필요한 기능들만을 모아',
                desc: '기획이 완료된 디자인 스킨으로 개발기간 단축 가능!'
              },
              {
                title: '원하는 디자인으로 커스텀하자',
                desc: '내 취향에 맞게 디자인 스킨 수정 작업 진행 가능!'
              }
            ],
            price: {
              org_price: 1320000,
              sale: 20,
              price: 500000
            },
            time: '평균 1개월',
            link: '/store'
          },
          {
            title: '주문 제작',
            subTitle: '완벽함을 위한 VIP 솔루션',
            desc: 'A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로  런칭할 수 있는 솔루션',
            img: 'img_ordermake',
            checks: [
              {
                title: '기획부터 개발까지',
                desc: '기획 전 온/오프라인 미팅을 통해 고객의 정확한 니즈 파악 후 개발 착수'
              },
              {
                title: '1:1 맞춤 고객 대응',
                desc: '추가 신청 없이도 제공되는 케어서비스. 맞춤제작 고객 전용 1:1 대응팀 배정'
              }
            ],
            link: '/service_inquiry_reg'
          },
          {
            title: '플랫폼 운영 솔루션 API',
            subTitle: '플랫폼 운영에 필요한 관리자 사이트+백엔드',
            desc: '백엔드 기능과 관리자 사이트 기반으로 원하는대로 구현하고 싶은 팀에게\n' +
              '안정적인 관리 솔루션 제공, 프론트엔드 기본 소스코드도 요청 시 제공가능',
            img: 'img_api',
            checks: [
              {
                title: '개발자 혼자 웹서비스를 다 구축하기 어려운 경우',
                desc: '런치팩의 백엔드 API를 고객의 프론트엔드에 연동하여 서비스 운영 가능, 효율적인 개발과 서비스 제작이 가능'
              },
              {
                title: '개발자 없이도 안정적인 운영',
                desc: '안정적인 런치팩의 서버를 기반으로 추후 업데이트와 에러대응까지'
              }
            ],
            link: '/solution_api_reg'
          }
        ],
        extras: [
          {
            subTitle: 'Launchpack Care',
            title: '케어서비스',
            icon: 'u_headphones-alt',
            desc: '이용 중 발생하는 문제를 빠르게 대응할 수 있도록 담당자를 배치하고 1:1 문의 창구 개설',
            link: '/care_service/intro'
          },
          {
            subTitle: 'Hosting',
            title: '호스팅',
            icon: 'u_database-alt',
            desc: '런치팩이 구축한 안정적인 AWS 기반의 클라우드 서버',
            link: '/hosting/intro'
          },
          {
            subTitle: 'Maintenance',
            title: '수정개발',
            icon: 'u_cog',
            desc: '서비스 런칭 이후에도  디자인 수정/추가 개발 진행 가능',
            star: '*퀵빌더 이용 고객은 해당 부가서비스 이용이 불가합니다.'
          }
        ],
      }
    },
    methods: {
      icon(name) {
        return `/static/icon/${name}.svg`;
      },
      img(name) {
        return `/static/img/home/${name}.png`;
      },
      clickExtra(item) {
        if(item.link) {
          this.routerPush(item.link);
        }
      }
    }
  }
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .container
    height 100%
    display flex
    align-items center
    flex-wrap wrap
    max-width 1152px !important

  .section-1
    background url(/static/img/home/background_main_banner.png)
    background-repeat no-repeat
    background-position center
    background-size cover
    height 794px
    overflow hidden

  .section-2
    height 656px

  .section-3
    padding 100px 0 124px 0
    background-color $gray4

  .section-4
    padding 112px 0

  .section-5
    padding 100px 0

  .section-6
    padding 100px 0 150px 0
    background url(/static/img/home/background_img_plugin.png)
    background-size cover
    background-position center
    background-repeat no-repeat

  .section-7
    padding 150px 0
    background-color $gray4

  .section-8
    padding 100px 0 50px 0

  .section-9
    padding 80px 0

  .pattern-dot
    position absolute
    top calc(50% + 210px)
    left 0

  .circle1
  .circle2
  .circle3
    position absolute

  .circle1
    top -8px
    left 160px
    width 358px
    height 358px
    background $primary
    opacity 0.5
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)

  .circle2
    left -535px
    top 406px
    width 575px
    height 575px
    background #17FF90
    opacity 0.1
    filter blur(410px)
    -webkit-backdrop-filter blur(410px)
    backdrop-filter blur(410px)

  .circle3
    width 673px
    height 673px
    left 50%
    top 619px
    background #7B61FF
    opacity 0.15
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)

  .selection1-img
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    z-index 1

  .button-home
    border-radius 8px
    padding 16px 24px
    width 182.5px
    text-align left

  .button-home-circle
    border-radius 100%
    padding 38px 16px
    width 124px
    height 124px
    text-align left

  .button-home:hover
  .button-home-circle:hover
    box-shadow 0 10px 20px rgba(255, 102, 0, 0.15)

  .button-primary
  .button-home-circle
    border 0
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
    color white

  .button-primary-light
    background-color white
    color $primary
    border 1px solid $primary

  .sec2-box
    background-color $gray4
    padding 20px 24px
    display flex
    align-items center
    width 466px
    border-radius 12px

  .step-icon
    width 80px
    height 80px
    background-color white
    border-radius 20px
    box-shadow 0 20px 40px rgba(0, 0, 0, 0.04)
    display flex
    justify-content center
    align-items center

  .dot-line
    flex 1
    overflow hidden
    margin-left 32px

  .dot
    width 4px
    height 4px
    background-color $primary
    margin-right 8px
    display inline-block
    border-radius 100%

  .card-product
    flex 1
    height 320px
    display flex
    justify-content space-between
    border-radius 12px
    align-items center
    overflow hidden
  .card-product:hover
    filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))

  .design-market
    background-color #7B61FF
    margin-right 24px

  .order
    background linear-gradient(134.45deg, #00BF91 41.03%, #17FF90 105.39%)
    margin-left 24px

  .card-content
    color white
    margin-left 40px
    flex 1

  .ready-item
    text-align center
    background-color white
    border 1px solid $gray1
    box-shadow 0 20px 20px rgba(0, 0, 0, 0.04)
    border-radius 12px
    height 160px
    display flex
    align-items center
    justify-content center

  .card-service
    padding 50px 40px
    background-color white
    border 1px solid $gray1
    border-radius 12px
    box-shadow 0 20px 20px rgba(0, 0, 0, 0.04)
    display flex
    flex-direction column
    justify-content space-between

  .card-divider
    width 100%
    height 1px
    background-color $gray3
    margin 28px 0

  .card-service-price
    border-top 1px solid $gray3
    padding-top 28px
    margin-top 28px

  .card-extra
    border 1px solid $gray1
    padding 32px 40px
    border-radius 8px
  .card-extra:hover
    background-color $gray4
  .card-extra-icon-bg
    background-color $primary-light2
    border-radius 12px
    width 64px
    height 64px
    text-align center
    line-height 76px

  // tablet
  @media (max-width:1024px)
    .pc
      display none
    .tablet
      display block
    .mobile
      display none

    .button-home
      padding 10px 12px
      width 140px

    .section-2
    .section-3
    .section-4
    .section-5
    .section-6
    .section-7
    .section-8
    .section-9
      padding 60px 0
      height initial

    .sec2-box
      width 488px
      margin-top 12px

    .step-icon
      margin-right 20px

    .ready-item
      height 88px

    .card-product-mobile
      flex 1
      border-radius 8px
      overflow hidden
      filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))
      display flex
      flex-direction column
      height 180px

    .card-content
      margin 20px
      flex 1

    .design-market
      margin-right 0
      background-image url(/static/img/home/mockup_design_market_mo.png)
      background-size contain
      background-position right
      background-repeat no-repeat
    .order
      margin-left 0

    .order-background-image
      background-image url(/static/img/home/img_order_make_mo.png)
      background-size contain
      background-position right
      background-repeat no-repeat
      height 100%

  @media (max-width:640px)
    .pc
      display none
    .tablet
      display none
    .mobile
      display block
    .container
      flex-direction column
      flex-wrap nowrap
      align-items flex-start

    .section-1
      background url(/static/img/home/background_main_banner_mo.png)
      background-repeat no-repeat
      background-position center
      background-size cover
      height calc(100vh - 60px)
      position relative

    .section-6
      background url(/static/img/home/background_mo.png)
      background-size cover
      background-position center

    .section-2
    .section-3
    .section-4
    .section-5
    .section-6
    .section-7
    .section-8
    .section-9
      padding 40px 0
      height initial

    .section-3
    .section-7
      background-color $gray4

    .sec2-box
      width 100%
      padding 18px 12px

    .circle1
      top -113px
      right -10px
      left initial
      width 241px
      height 241px

    .circle2
      left -115px
      top 294px
      width 274px
      height 274px
      background #7B61FF
      opacity 0.3
      filter blur(320px)
      -webkit-backdrop-filter blur(320px)
      backdrop-filter blur(320px)

    .circle3
      width 215px
      height 215px
      left 244px
      top 353px
      background #17FF90
      opacity 0.1
      filter blur(130px)
      -webkit-backdrop-filter blur(130px)
      backdrop-filter blur(130px)

    .selection1-img
      position static
      transform initial
      width 328px
      margin-right 56px

    .button-home
      padding 10px 12px
      width 100%

    .card-content
      margin 20px
      flex 1

    .step-icon
      width 48px
      height 48px
      margin-right 20px
      border-radius 12px

    .card-product-mobile
      flex 1
      border-radius 8px
      overflow hidden
      filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))
      display flex
      flex-direction column
      height auto

    .design-market
      margin-right 8px
    .order
      margin-left 8px

    .bounce
      animation-iteration-count 10
      -webkit-animation-duration 1s
      animation-duration 1s
      -webkit-animation-name bounce
      animation-name bounce

    .ready-item
      border-radius 4px
      box-shadow none
      padding 12px 8px
      height initial

    .card-service
      box-shadow 0 6px 10px rgba(0, 0, 0, 0.05)
      margin-bottom 20px
      padding 20px
    .card-service:last-child
      margin-bottom 0

    .card-divider
      margin 12px 0

    .card-service-price
      margin-top 12px
      padding-top 12px

    .card-extra
      width 100%
      border 1px solid $gray1
      padding 20px
      border-radius 8px
      margin-bottom 12px

    .card-extra-icon-bg
      width 48px
      height 48px
      line-height 60px

    @-webkit-keyframes bounce {
      0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
      40% {-webkit-transform: translateY(-8px) }
      60% {-webkit-transform: translateY(-4px);}
    }
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-8px);}
      60% {transform: translateY(-4px);}
    }

    .design-market
      background-image initial
    .order-background-image
      background-image initial
</style>
